import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import H1 from '../../components/header1';
import {
  FirstTab,
  SecondTab,
  ThirdTab,
  FourthTab,
  FifthTab,
  SixthTab,
  // SeventhTab,
} from '../../components/ourBrandsTabs';
import { TabMenu, TabPanel } from '../../components/Tabs';
import { SUB_PAGES } from '../../utils/constants';
import { isBrowser } from '../../utils/functions';

const { titles, ourBrandsTabs } = SUB_PAGES.OUR_BRANDS;

const getIndexFromPathName = pathname => {
  const index = ourBrandsTabs.findIndex(
    element => element.tabLink === pathname
  );
  return index !== -1 ? index : 0;
};

const Index = ({ data }) => {
  const logoBandData = data;
  const theme = useTheme();
  const location = isBrowser() && window.location.pathname;
  const [selectedTab, setSelectedTab] = useState(
    getIndexFromPathName(location)
  );

  const handleChange = (_, index) => {
    setSelectedTab(index);
    const urlToPush = ourBrandsTabs[index].tabLink;
    if (isBrowser()) {
      window.history.pushState(null, null, urlToPush);
    }
  };

  const handleChangeIndex = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    if (isBrowser) {
      setSelectedTab(getIndexFromPathName(window.location.pathname));
    }
  });

  return (
    <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Grid container direction="column" style={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Container maxWidth="xl">
            <H1 title="OUR BRANDS" />
          </Container>
        </Grid>
        <TabMenu value={selectedTab} onChange={handleChange} tabs={titles} />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={selectedTab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={ourBrandsTabs[0].tabValue}
            currentValue={selectedTab}
          >
            <FirstTab logoBandData={logoBandData} />
          </TabPanel>
          <TabPanel
            value={ourBrandsTabs[1].tabValue}
            currentValue={selectedTab}
          >
            <SecondTab logoBandData={logoBandData} />
          </TabPanel>
          <TabPanel
            value={ourBrandsTabs[2].tabValue}
            currentValue={selectedTab}
          >
            <ThirdTab logoBandData={logoBandData} />
          </TabPanel>
          <TabPanel
            value={ourBrandsTabs[3].tabValue}
            currentValue={selectedTab}
          >
            <FourthTab logoBandData={logoBandData} />
          </TabPanel>
          <TabPanel
            value={ourBrandsTabs[4].tabValue}
            currentValue={selectedTab}
          >
            <FifthTab logoBandData={logoBandData} />
          </TabPanel>
          <TabPanel
            value={ourBrandsTabs[5].tabValue}
            currentValue={selectedTab}
          >
            <SixthTab logoBandData={logoBandData} />
          </TabPanel>
          {/* <TabPanel
            value={ourBrandsTabs[6].tabValue}
            currentValue={selectedTab}
          >
            <SeventhTab logoBandData={logoBandData} />
          </TabPanel> */}
        </SwipeableViews>
      </Grid>
    </Container>
  );
};

export default Index;

export const pageQuery = graphql`
  query LogoBandQuery {
    allFile(
      filter: {
        name: {
          in: [
            "biogreen_color"
            "ozosteril_color"
            "safesteril_color"
            "spirajoule_color"
            "scanship_color"
            "chevensen_color"
          ]
        }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
