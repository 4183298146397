import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { COLOR_CORAL } from '../../utils/constants';
import Button from '@material-ui/core/Button';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import HubspotForm from 'react-hubspot-form';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
    },
  },
  container2: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
    },
  },
  container3: {
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      paddingTop: 200,
      marginTop: 40,
    },
  },
  imageGrid: {
    flexGrow: 1,
    padding: '20px 0px 40px 100px',
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
      borderBottom: 'none',
      width: '100%',
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
  },
  imageGrid2: {
    flexGrow: 1,
    padding: '20px 100px 0px 0px',
    '@media (max-width:900px)': {
      padding: '20px 50px 0px 0px',
    },
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
  textGrid: {
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
    },
    '& a': {
      color: COLOR_CORAL,
      textDecoration: 'none',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    padding: '20px 0px 40px 100px',
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 80px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  textGrid2: {
    alignSelf: 'center',
  },
  textGrid3: {
    '& p': {
      maxWidth: 600,
    },
    '& h3': {
      marginTop: 0,
      maxWidth: 600,
    },
    padding: '40px 0px 40px 0px',
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 0px',
    },
    '@media (max-width:900px)': {
      padding: '40px 0px 40px 0px',
    },
    '@media (max-width:600px)': {
      padding: '40px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  contactButton: {
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '12px',
    textTransform: 'none',
    fontFamily: 'Arial',
    color: 'white',
    padding: '8px 20px 8px 20px',
    backgroundColor: COLOR_CORAL,
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
    '@media (max-width:800px)': {
      marginTop: 20,
    },
  },
  iframeWrapper: {
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    paddingTop: 25,
    height: 0,
  },
  formWrapper: {
    paddingBottom: 40,
  },
});

const BrandPage = ({ brandData, logoBandData }) => {
  const classes = useStyles();
  const myRef = useRef(null);
  const {
    pageTitle,
    formId,
    formPortalId,
    mainImage,
    brandDescription,
    brandDescriptionSecond,
    brandImageSecond,
    brandDescriptionThird,
    brandImageThird,
    contactFormDescription,
  } = brandData;

  const handleMoveToContact = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {/* Use Helmet if form script does not initialize on Safari
      <Helmet>
        <script
          charset="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/shell.js"
        ></script>
      </Helmet> */}
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          {documentToReactComponents(brandDescription.json)}
          <Button
            variant="contained"
            className={classes.contactButton}
            onClick={handleMoveToContact}
          >
            Contact us
          </Button>
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <Img fluid={mainImage.fluid} alt="Biogreen" />
        </Grid>
      </Grid>
      {/* <LogoBand logoBandData={logoBandData} /> */}
      <Grid container className={[classes.container, classes.container2]}>
        <Grid item sm={6} className={classes.imageGrid2}>
          <Img fluid={brandImageSecond.fluid} alt="Vow ship solutions" />
        </Grid>
        <Grid item sm={6} className={[classes.textGrid, classes.textGrid2]}>
          {documentToReactComponents(brandDescriptionSecond.json)}
        </Grid>
      </Grid>
      <Grid container className={classes.container3}>
        <Grid item sm={5} className={classes.textGrid}>
          <div className={classes.formWrapper} ref={myRef}>
            {documentToReactComponents(contactFormDescription.json)}
          </div>
          {formId && formPortalId && (
            <HubspotForm
              portalId={formPortalId}
              formId={formId}
              onSubmit={() => console.log('Submit!')}
              // onReady={form => console.log('Form ready!')}
              loading={<div>Loading...</div>}
            />
          )}
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <Img fluid={brandImageThird.fluid} alt="Cruise ship" />
          <div className={classes.textGrid3}>
            {documentToReactComponents(brandDescriptionThird.json)}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default BrandPage;
