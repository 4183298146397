import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BrandPage from './BrandPage';

export default ({ logoBandData }) => {
  const data = useStaticQuery(query).allContentfulBrandPage.edges[0].node;

  return <BrandPage brandData={data} />;
};

const query = graphql`
  query BiogreenBrandQuery {
    allContentfulBrandPage(filter: { pageTitle: { eq: "Biogreen" } }) {
      edges {
        node {
          pageTitle
          formId
          formPortalId
          mainImage {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          brandDescription {
            json
          }
          brandDescriptionSecond {
            json
          }
          brandImageSecond {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          brandDescriptionThird {
            json
          }
          brandImageThird {
            fluid(quality: 90, maxWidth: 1383, maxHeight: 720) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          contactFormDescription {
            json
          }
        }
      }
    }
  }
`;
